<template>
    <div class="ocr">
      <div class="proj_box">
        <div class="proj_name proj_center_title">上传身份证</div>
      </div>
      <div class="uploadTitle">
        <span>上传证件照片</span>
      </div>
      <div class="uploadStandard">每次最多上传0/2张，单张文件不超过5MB</div>
      <div class="ocrBox">
        <template v-if="isWeixin">
          <div class="upload-img" @click="chooseImage('front')">
            <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
          </div>
          <div class="upload-img" @click="chooseImage('back')">
            <div class="upload-img-b" :style="{ backgroundImage: 'url(' + idcard_back + ')' }"></div>
          </div>
        </template>
        <template v-else>
          <ImageUploader class="upload-img" v-model="img_front" single @change="uploadFront">
            <template slot="content">
              <div class="upload-img-a" :style="{ backgroundImage: 'url(' + idcard_front + ')' }"></div>
            </template>
          </ImageUploader>
          <ImageUploader class="upload-img" v-model="img_back" single @change="uploadBack">
            <template slot="content">
              <div class="upload-img-b" :style="{ backgroundImage: 'url(' + idcard_back + ')' }"></div>
            </template>
          </ImageUploader>
        </template>
      </div>
      <div class="standard">上传标准</div>
      <div class="uploadMark">证件必须是清晰彩色原件电子版本。可以是扫描件或是数码拍摄照片。仅支持jpg，png，jpeg的图片格式。</div>
      <div class="standardImg">
        <img src="/static/uploadExp.png" />
      </div>
      <div class="identity">身份信息</div>
      <van-cell-group class="information">
        <van-cell title="姓名" :value="form.name" />
        <van-cell title="身份证号" :value="form.idsn" />
      </van-cell-group>
  
      <div class="tips">
        <span>信息自动识别，无法识别请重新上传图片</span>
      </div>
      <div class="btn">
        <van-button round type="info" size="large" class="up" :disabled="disabled" @click="next">提交</van-button>
      </div>
      <div class="goBack" @click="goback">
        <img src="/static/goBack.png" />
      </div>
    </div>
  </template>
  
  <script>
  import { Ocr } from '@/components/ocr.js'
  import ImageUploader from '@/components/ImageUploader'
  import { Toast } from 'vant'
  import { idcardOcr, updateIdsn } from '@/api/api'
  import { mapState, mapActions } from 'vuex'
  export default {
    name: '',
    components: {
      ImageUploader
    },
    data() {
      return {
        disabled: false,
        form: {
          name: '',
          idsn: '',
        },
        idcard_front: '/static/idcard_front.png',
        idcard_back: '/static/idcard_back.png',
        img_front: [], // 非微信环境上传身份证正面
        img_back: [], // 非微信环境上传身份证反面
      }
    },
    mixins: [Ocr],
    computed: {
      ...mapState(['userId', 'registerInfo','doctorInfo', 'utm_source'])
    },
    methods: {
      ...mapActions(['SET_USER_ID', 'SET_DOCTOR_INFO']),
  
      async ocr() {
        try {
          let res = await idcardOcr({
            idCards: this.idcards,
            projectId: localStorage.getItem('project_id')
          })
          if(this.doctorInfo) {
            if(this.doctorInfo && res.idsn === this.doctorInfo.idsn) {
              this.form.idsn = res.idsn
              this.form.name = res.name
            } else {
              this.idcard_front = '/static/idcard_front.png'
              this.idcards[1].image = ''
              Toast(`请上传该医生本人的身份证`)
            }
          } else {
            this.idcard_front = '/static/idcard_front.png'
            this.idcards[1].image = ''
            Toast('当前医生信息错误，请退出重试')
          }
        } catch (err) {
          Toast(err.response.data.message)
        }
      },

      async next() {
        if (!this.idcards[0].image || !this.idcards[1].image) {
          Toast('请上传证件照片')
          return false
        }
        if(this.doctorInfo) {
          if(!this.form.name || !this.form.idsn) {
            Toast(`请上传该医生本人的身份证`)
            return
          }
        } else {
          Toast('当前医生信息错误，请退出重试')
          return
        }
        this.disabled = true
        const data = {
          projectDoctorId: this.$route.query.id,
          doctorId: this.$route.query.doctor_id,
          idCards: [this.idcards[0].image, this.idcards[1].image]
        }
        await updateIdsn(data).then(() => {
          Toast({
            duration: 1500, // 持续展示 toast
            forbidClick: true,
            message: '认证成功'
          })
          this.$router.replace({
            path: '/me'
          })
          this.disabled = false
        }).catch(err => {
          this.disabled = false
          Toast(err.response.data.message)
        })
      },

      goback() {
        this.$router.replace({
          path: '/me'
        })
      },
      uploadFront() {
        const len = this.img_front.length
        if (len > 0) {
          this.idcard_front = this.img_front[0]
          this.idcards[1].image = this.img_front[0]
          this.fileUploadToOcr()
        } else {
          this.idcard_front = '/static/idcard_front.png'
          this.idcards[1].image = ''
        }
      },
      uploadBack() {
        const len = this.img_back.length
        if (len > 0) {
          this.idcard_back = this.img_back[0]
          this.idcards[0].image = this.img_back[0]
          this.fileUploadToOcr()
        } else {
          this.idcard_back = '/static/idcard_front.png'
          this.idcards[0].image = ''
        }
      }
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .ocr {
    height: 100%;
    width: 100%;
    // padding: 0px 11px 0 9px;
  
    box-sizing: border-box;
    text-align: left;
  
    .proj_box {
      // width: 100%;
      display: flex;
      align-items: center;
      padding: 10px 16px 10px 16px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.06);
      margin-bottom: 22px;
  
      .proj_title {
        font-size: 13px;
        height: 25px;
        background: #e2eeff;
        color: #4b99ff;
        text-align: center;
        line-height: 25px;
        border-radius: 3px;
        padding: 0 3px;
        font-weight: bold;
      }
  
      .proj_name {
        font-size: 15px;
        color: #292929;
        margin-left: 14px;
      }
  
      .proj_center_title {
        flex:1;
        text-align: center;
        font-weight: bold;
        margin-left: 0;
      }
    }
  
    .standard,
    .identity,
    .uploadTitle {
      font-size: 15px;
      color: #232323;
      margin-bottom: 13px;
      margin-left: 16px;
      margin-right: 16px;
      font-family: PingFangSC-Medium;
    }
  
    .uploadTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
  
      .selectpan {
        color: #4b99ff;
        font-weight: 500;
        font-size: 12px;
        display: flex;
  
        div {
          width: 0px;
          height: 0px;
          border-top: 10px solid #4b99ff;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-bottom: 10px solid transparent;
          margin-top: 4px;
          margin-left: 4px;
          margin-bottom: -6px;
          transition: 0.3s linear;
        }
  
        .active {
          transform: rotate(180deg);
          transition: 0.3s linear;
          margin-top: -6px;
        }
      }
    }
  
    .uploadStandard,
    .uploadMark {
      // color: #A8A8A8;
      color: #565656;
      font-size: 12px;
      margin-bottom: 3px;
      margin-left: 16px;
      margin-right: 16px;
    }
  
    .ocrBox {
      height: 128px;
      width: 100%;
      display: flex;
      justify-content: center;
  
      .upload-img {
        // height: 94px;
        // width: 139px;
        height: 96px;
        width: 165px;
        background-position: left top, right top, right bottom, left bottom;
        background-repeat: no-repeat;
        background-size: 16px;
        // padding: 20px;
        padding: 15px;
  
        .upload-img-a,
        .upload-img-b {
          width: 100%;
          height: 0;
          padding-top: 63%;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          background-color: #fff;
          box-shadow: 0 0 20px rgba(0, 0, 0, 0.075);
          border-radius: 5px;
        }
      }
    }
  
    .uploadMark {
      margin-bottom: 26px;
    }
  
    .standardImg {
      width: 100%;
      height: 90px;
      padding: 0 25px;
      box-sizing: border-box;
      margin-bottom: 20px;
  
      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  
    .information {
      margin-bottom: 10px;
    }
  
    .tips {
      // color: #A8A8A8;
      color: #565656;
      font-size: 12px;
      padding-bottom: constant(safe-area-inset-bottom);
      padding-bottom: env(safe-area-inset-bottom);
      margin-bottom: 70px;
      box-sizing: border-box;
      text-align: center;
    }
  }
  </style>
  